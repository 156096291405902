.hours-container {
  padding: 1rem;
  background-color: whitesmoke;
  height: 93vh;
  width: 98vw;
  position: fixed;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  h1 {
    font-size: 1.8rem;
    font-weight: 700;
    color: #1a202c;
    margin-bottom: 1rem;
    text-align: center;
  }

  h2 {
    font-size: 1.4rem;
    font-weight: 600;
    color: #1a202c;
    margin: 1rem 0 0.5rem;
  }

  .date-filters {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    max-width: 1000px;
    margin: auto;

    label {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      font-weight: 500;
      font-size: 0.9rem;
      flex: 1;
    }

    select,
    input {
      padding: 0.25rem;
      border: 1px solid #e2e8f0;
      border-radius: 4px;
      font-size: 0.9rem;
    }

    button {
      padding: 0.25rem 1rem;
      background-color: #f8f9fa;
      border: 2px solid #e2e8f0;
      border-radius: 4px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.2s ease;
      color: black;
      font-size: 0.9rem;
      flex: 5;

      &:hover {
        background-color: #e2e8f0;
      }
    }
  }

  .employee-hours-table {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto 1rem;
    border-collapse: separate;
    border-spacing: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;

    th,
    td {
      padding: 0.5rem;
      text-align: left;
      border-bottom: 1px solid #e2e8f0;
    }

    th {
      background-color: #f8f9fa;
      color: black;
      font-weight: bold;
      font-size: 0.9rem;
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }

    td {
      background-color: white;
      font-size: 0.9rem;
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: #f8f9fa;
          td {
            background-color: #f8f9fa;
          }
        }

        &:last-child td {
          border-bottom: none;
        }

        &.active-clock-in td {
          background-color: #fef3c7;
          color: #92400e;
          font-weight: 500;
        }
      }
    }

    tfoot tr td {
      background-color: #f8f9fa;
      font-weight: bold;
      border-top: 2px solid #e2e8f0;
    }
  }

  .detailed-clock-entries-table,
  .tab-transactions-table {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto 0.5rem;
    border-collapse: collapse;
    font-size: 0.7rem;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;

    th,
    td {
      padding: 0.25rem;
      text-align: left;
      border: 1px solid #e2e8f0;
    }

    th {
      background-color: #f8f9fa;
      font-weight: 600;
    }

    tr.active-clock-in td {
      background-color: #fef3c7;
    }
  }

  .short-entry {
    td {
      background-color: #ffadad !important;
    }
  }

  .detailed-hours,
  .detailed-tabs {
    table {
      .recurring-entry {
        font-style: italic;

        td {
          border-top: 2px dashed #e2e8f0;
          border-bottom: 2px dashed #e2e8f0;
        }

        button.edit-button {
          padding: 0.25rem 0.75rem;
          background-color: #e2e8f0;
          border: 1px solid #cbd5e0;
          border-radius: 4px;
          cursor: pointer;
          transition: all 0.2s ease;

          &:hover {
            background-color: #cbd5e0;
          }
        }
      }
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .modal-content {
      background-color: white;
      padding: 1.5rem;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      width: 90%;
      max-width: 400px;

      h3 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        text-align: center;
      }

      label {
        display: block;
        margin-bottom: 0.75rem;
        font-weight: 500;
        font-size: 0.9rem;

        select,
        input {
          display: block;
          width: 100%;
          max-width: 200px;
          margin: 0.25rem auto;
          padding: 0.4rem;
          border: 1px solid black;
          border-radius: 4px;
          font-size: 0.9rem;
          text-align: center;
        }

        .help-text {
          display: block;
          font-size: 0.8rem;
          color: #666;
          margin-top: 0.25rem;
          font-style: italic;
        }
      }

      .modal-actions {
        display: flex;
        gap: 0.75rem;
        justify-content: flex-end;
        margin-top: 1rem;

        button {
          padding: 0.4rem 1rem;
          border: 2px solid black;
          border-radius: 4px;
          font-weight: 600;
          font-size: 0.9rem;
          cursor: pointer;
          transition: all 0.2s ease;
          color: black;

          &:first-child {
            background-color: #fef3c7;
            border-color: black;
            &:hover {
              background-color: #fde68a;
            }
          }

          &:last-child {
            background-color: #f8f9fa;
            &:hover {
              background-color: #e2e8f0;
            }
          }
        }
      }
    }
  }

  .add-entry-section {
    text-align: center;
    margin: 0.75rem 0;

    button {
      padding: 0.5rem 1.5rem;
      max-width: 200px;
      margin: 0 0.5rem;
      background-color: #f8f9fa;
      border: 2px solid #e2e8f0;
      border-radius: 6px;
      font-weight: 600;
      font-size: 0.9rem;
      cursor: pointer;
      transition: all 0.2s ease;
      color: black;
      &:hover {
        background-color: #e2e8f0;
      }
    }

    .addEntryButton,
    .addRecurringButton {
      display: inline-block;
    }
  }

  @media print {
    background-color: white;
    padding: 0;
    height: auto;
    width: auto;
    position: static;
    overflow: visible;

    .no-print {
      display: none;
    }

    h1 {
      font-size: 1.5rem;
      margin-bottom: 0.75rem;
    }

    h2 {
      font-size: 1.2rem;
      margin: 0.75rem 0 0.4rem;
    }

    .employee-hours-table {
      box-shadow: none;
      border: 1px solid #e2e8f0;
      font-size: 0.8rem;
      page-break-after: always;

      th,
      td {
        padding: 0.3rem;
      }

      tr:nth-child(even) {
        background-color: #cacaca !important;
        td {
          background-color: #cacaca !important;
        }
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
      }

      tfoot tr {
        background-color: #f0f0f0 !important;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
      }
    }

    .detailed-clock-entries-table,
    .tab-transactions-table {
      box-shadow: none;
      border: 1px solid #e2e8f0;
      font-size: 0.7rem;
      page-break-inside: avoid;

      th,
      td {
        padding: 0.2rem;
      }
    }

    .active-clock-in td {
      background-color: #fff !important;
      color: #000 !important;
      font-weight: normal !important;
    }

    .short-entry td {
      background-color: #ffadad !important;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
  }

  .editButton {
    font-size: 0.7rem !important;
    padding: 0.2rem 0.4rem !important;
    background-color: #f8f9fa;
    border: 2px solid #e2e8f0;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    color: black;
    &:hover {
      background-color: #e2e8f0;
    }
  }
}
